import React,{useState} from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { actualizarInediRank } from '../../../../actions/reportesActions';
import { updateStateModalBasic } from '../../../../actions/modalActions';

const EditInedirank = ({ id_matricula, inedirank,updateInediRank }) => {
    const dispatch = useDispatch();
    let [rank, setRank] = useState(inedirank);
    const { usuario } = useSelector(state => state.usuario);



    const handleReview = async () => {
        try {
            // Esperamos a que se complete la primera operación
            await dispatch(actualizarInediRank(id_matricula, usuario.id_usuario));
            
            // Si llegamos aquí, significa que no hubo error
            const newRank = parseInt(rank) + 30;
            await updateInediRank(id_matricula, newRank);
            dispatch(updateStateModalBasic());
        } catch (error) {
            // Si hay error, no se ejecutará el update del rank
            console.log('Error en el proceso:', error);
        }
    };


    return (
        <div className="container p-4">
            <Form >
                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Puntos totales INEDIRANK</Form.Label>
                        {/*<Form.Control
                            type="text"
                            value={rank}
                            onChange={(e) => setRank(e.target.value)}
                        />*/}
                        <Button
                            className="btn-spinner"
                            variant="info"
                            onClick={()=>handleReview()}
                        >
                            Agregar 30 puntos Review
                        </Button>
                    </Form.Group>
                    {/*<Form.Group as={Col} md={6} className="text-left">
                        <Button
                            className="btn-spinner"
                            variant="info"
                            type="submit"
                        >
                            Guardar
                        </Button>
                    </Form.Group>*/}
                </Form.Row>
            </Form>
        </div>
    );
};


export default EditInedirank;
import React from 'react';
import {Button} from "react-bootstrap";
import { useDispatch } from 'react-redux';
import NuevaPregunta from './NuevaPreguntaExamen';
import { updateStateModalBasic } from '../../../actions/modalActions';
import ModalBasicwithRedux from '../../../containers/ModalBasicwithRedux';
const Pregunta = ({id_examen,id_pregunta,pregunta,puntaje,eliminarPregunta,explicacion_correcta,opciones}) => {

  
  let preguntaElegida= {id_examen,id_pregunta,pregunta,puntaje,explicacion_correcta,opciones};

  const dispatch = useDispatch();
  const datosPregunta ={
    idExamen:id_examen,
    idPregunta:id_pregunta
  }

  const editarPregunta = (datos) => {
      dispatch(updateStateModalBasic(
        <NuevaPregunta
        preguntaElegida={datos}
        />,
        'Editar Pregunta'
      ));
    };

  const confirmarEliminiacion = (datos) => {
   
    dispatch(eliminarPregunta(datosPregunta))
  }
  return ( 
    <>
    <ModalBasicwithRedux/>
    <div style={{display:"flex", textAlign:"left", padding:"10px"}}>
      <div style={{width:'350px'}}>{pregunta}</div>
      <div style={{minWidth:"60px", textAlign:'center'}}>{puntaje}</div>
      {/*<div className='mr-3'><Button variant='warning'  onClick={() => editarPregunta(preguntaElegida)}> <i className="fa fa-edit" aria-hidden="true"></i> </Button></div>*/}

      <div ><Button variant='danger'  onClick={() => confirmarEliminiacion(datosPregunta)}> <i className="fa fa-trash" aria-hidden="true"></i> </Button></div>
    </div>
    </>
  );
}
 
export default Pregunta;
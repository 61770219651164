import {examenesTypes} from './types'
import authAxios from '../config/authAxios'
import Swal from 'sweetalert2';

export const guardarExamen = dataExamen => async dispatch => {
    let respuesta = await authAxios.post(`/admin/ex_guardarExamen`, dataExamen);
    return {mensaje: true, id_examen: respuesta.data.id_examen};
}

export const listarExamenes = () => async dispatch => {
    let examenes = await authAxios.get(`/admin/ex_mostrarExamenesVirtuales`)

    dispatch({
        payload: examenes.data,
        type: examenesTypes.LISTAR_EXAMENES_EXITO
    })
}

export const obtenerExamen = (sesion) => async dispatch => {

    dispatch({
        type:examenesTypes.OBTENER_EXAMEN_SESION
    })
    let examen = await authAxios.get(`/admin/ex_mostrarExamenSesionAdmin/${sesion.id_sesion}`)
    if(!examen.data.error)
    { 
         dispatch({
        payload: examen.data,
        type: examenesTypes.OBTENER_EXAMEN_SESION_EXITO
         })
    }
}


export const guardarPregunta = dataPregunta => async dispatch => {

    let respuesta = await authAxios.post(`/admin/ex_guardarPregunta`, dataPregunta);
    if (!respuesta.data.error) {    
    dispatch({
        payload:respuesta.data,
        type:examenesTypes.INSERTAR_PREGUNTA
    })
        return {mensaje: true}
    }
    return {mensaje: false}
}

export const editarPregunta = dataPregunta => async dispatch => {
    console.log(dataPregunta)

    let respuesta = await authAxios.post(`/admin/ex_editarPregunta`, dataPregunta);
    if (!respuesta.data.error) {    
    dispatch({
        payload:respuesta.data,
        type:examenesTypes.INSERTAR_PREGUNTA
    })
        return {mensaje: true}
    }
    return {mensaje: false}
}

export const verExamen = idExamen => async dispatch => {

    let respuesta = await authAxios.get(`/admin/ex_obtenerPreguntasOpcionesAdmin/${idExamen}`);
    if (!respuesta.data.error) {

        return {mensaje: true,examen:respuesta.data.preguntas}
    }
    return {mensaje: false}
}

export const eliminarPregunta = datosPregunta => {

    return async dispatch => {
         const swalWithBootstrapButtons = Swal.mixin({
           customClass: {
               confirmButton: "btn btn-success",
               cancelButton: "btn btn-danger"
           },
           buttonsStyling: false
           });
           swalWithBootstrapButtons
           .fire({
               title: "¿Estas seguro?",
               text: "Se eliminará la pregunta y sus alternativas",
               icon: "warning",
               showCancelButton: true,
               confirmButtonText: "Si, continuar!",
               cancelButtonText: "No, cancelar!",
               reverseButtons: true,
               showLoaderOnConfirm: true,
               preConfirm: async function() {
               //consultando para ver el mensaje de aprovación
               try {
                 let respuesta = await authAxios.post(`/admin/ex_eliminarPregunta`, datosPregunta);
                 if (!respuesta.data.error) {
                     dispatch({
                         payload:respuesta.data,
                         type:examenesTypes.INSERTAR_PREGUNTA
                     })
                 }
                     return true;
               } catch (error) {
                   console.log(error)
                   const msg = error.response !== undefined ? error.response.data.msg : error 
                   Swal.fire({
                       icon: "error",
                       title: "Oops...",
                       text: msg
                   })
               }
               },
               allowOutsideClick: () => !Swal.isLoading()
           })
           .then(result => {
               //mensaje que se elimino el archivo correctamente
               if (result.value === true) {
               swalWithBootstrapButtons.fire(
                   "Eliminado!",
                   "Se eliminó la pregunta correctamente",
                   "success"
               );
               }
           });
       }
 }

export const guardarRespuestasExamen = datosExamen => {

   return async dispatch => {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
          },
          buttonsStyling: false
          });
          swalWithBootstrapButtons
          .fire({
              title: "¿Estas seguro?",
              text: "Se guardarán tus respuestas y se desactivará el exámen",
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Si, continuar!",
              cancelButtonText: "No, cancelar!",
              reverseButtons: true,
              showLoaderOnConfirm: true,
              preConfirm: async function() {
              //consultando para ver el mensaje de aprovación
              try {
               let respuesta = await authAxios.post(`/admin/ex_guardarRespuestasExamen`, datosExamen);
                if (!respuesta.data.error) {
                   // console.log(respuesta.data)
                }
                    return true;
              } catch (error) {
                  console.log(error)
                  const msg = error.response !== undefined ? error.response.data.msg : error 
                  Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: msg
                  })
              }
              },
              allowOutsideClick: () => !Swal.isLoading()
          })
          .then(result => {
              //mensaje que se elimino el archivo correctamente
              if (result.value === true) {
              swalWithBootstrapButtons.fire(
                  "Enviado!",
                  "Se envió sus respuestas correctamente",
                  "success"
              );
              }
          });
      }
}
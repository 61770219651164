import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { filtrarTratos } from '../../../actions/leadsActions';
import useSelectAsesores from "../../../hooks/useSelectAsesores";
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import useSelectFases from '../../../hooks/useSelectFases';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';


const FormularioFiltroTratos = () => {
const dispatch = useDispatch();
const {usuario} = useSelector(state=>state.usuario)
const [asesor, SelectAsesor] = useSelectAsesores(null)
const [proyecto, SelectProyecto] = useSelectProyecto(null)
const [fase, SelectFase] = useSelectFases(null)
const [fechaFiltro, setFechaFiltro] = useState(() => new Date());
const [fechaFiltroHasta, setFechaFiltroHasta] = useState(() => new Date());


const traerDatos = () =>{
if(fechaFiltro!==null && fechaFiltroHasta!==null)
{ const datosFiltro = {
    asesor:asesor?asesor:null,
    fechaFiltroDesde: fechaFiltro ? moment(fechaFiltro).format('YYYY-MM-DD') : null,
    fechaFiltroHasta: fechaFiltroHasta ? moment(fechaFiltroHasta).format('YYYY-MM-DD') : null,
    proyecto: proyecto ? proyecto.id_proyecto : null,
    fase: fase ? fase : null,
  }
 // console.log(datosFiltro)
 
   dispatch(filtrarTratos(datosFiltro,usuario))
}
  else
  {
    avisoError("Debe seleccionar un rango de fecha por lo menos.")
  }
}

useEffect(() => {
  if(fechaFiltro!==null && fechaFiltroHasta!==null){
    traerDatos()
  }
}, [fechaFiltro, fechaFiltroHasta])

 return (
 
    <div className="box">
     <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro:
                  </h5>
                
                </div>
      <div className="row p-2">
      
        <div className="col-4 mb-2">
          <label htmlFor="">Asesor:</label>
          <SelectAsesor/>
        </div>
        <div className="col-5 mb-2">
          <label htmlFor="">Producto:</label>
          <SelectProyecto/>
        </div>
        <div className="col-4">
          <label htmlFor="">Desde:</label>
          <DatePicker
                selected={fechaFiltro}
                onChange={(date) =>setFechaFiltro(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                autoComplete="off"
                name="fechaFiltro"
                startDate={fechaFiltro}
                className="form-control"
                endDate={fechaFiltroHasta}
              />
        </div>
        <div className="col-4">
          <label htmlFor="">Hasta:</label>
          <DatePicker
                selected={fechaFiltroHasta}
                onChange={(date) =>setFechaFiltroHasta(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                className="form-control"
                name="fechaFiltroHasta"
                startDate={fechaFiltro}
                endDate={fechaFiltroHasta}
                minDate={fechaFiltro}
                autoComplete="off"
              />
        </div>
        <div className="col-4 mb-2">
          <label htmlFor="">Fase:</label>
          <SelectFase />
        </div>
        <div className="col-12 pt-4">
          <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>
        </div>
      </div>
    </div>
  
  
    );
};

export default FormularioFiltroTratos;

import React from 'react';
import Opcion from './Opcion';

const ListarOpciones = ({ opciones, eliminarOpcion }) => {
  console.log(opciones)
  const mostrarOpciones = () => {
    if(opciones.length > 0) {
      return opciones.map(opcion => (
        <Opcion 
          key={opcion.id_opcion}
          eliminarOpcion={eliminarOpcion}
          {...opcion}
        />
      ))
    } else {
      return <p>No hay opciones aún</p>
    }
  }

  return ( 
    <div className="ListarOpciones">
      {mostrarOpciones()}
    </div>
  );
}
 
export default ListarOpciones;
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Table } from "react-bootstrap";
import InfoModal from './infoModal';
import { updateStateModalBasic } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";
const ContenidoReporte = ({ resultado }) => {
    const dispatch = useDispatch();

    let color_recursos = ''

    if (resultado.organizacion.recursos_academicos.examenes.semaforo === 'verde' && resultado.organizacion.recursos_academicos.materiales.semaforo === 'verde') {
        color_recursos = 'verde'
    }
    else if (resultado.organizacion.recursos_academicos.examenes.semaforo === 'ambar' || resultado.organizacion.recursos_academicos.materiales.semaforo === 'ambar') {
        color_recursos = 'ambar'
    }
    else {
        color_recursos = 'rojo'
    }

    // Modal para ver descripcion larga
    const handleInfoModal = (tipo) => {
        dispatch(updateStateModalBasic(
            <InfoModal
                tipo={tipo}
            />,
            tipo
        ));
    };

    console.log(resultado)


    return (
        <div className="row">
            <div className="col-md-6">
                <ModalBasicwithRedux />
                <Card className="p-3 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <Card.Title>Implementación</Card.Title>
                        <Button variant="primary" onClick={() => handleInfoModal('Implementacion')} >Ver mas</Button>
                    </div>
                    <Card.Body>
                        <p className='mb-3'>Este KPI mide la eficiencia en la contratación y confirmación de docentes, asegurando que las clases estén confirmadas con 7 días de anticipación y que los profesores sean sensibilizados sobre la metodología ANDRO® al menos 5 días antes. Esto garantiza planificación efectiva y calidad educativa.</p>
                        <Table >
                            <thead>
                                <tr style={{ backgroundColor: '#262F48', color: '#fff' }}>
                                    <th colSpan={3}>
                                        Confirmar/Contratar docentes
                                    </th>
                                </tr>
                                <tr style={{ backgroundColor: '#657FC5', color: '#fff' }}>
                                    <th>
                                        N° de sesiones
                                    </th>
                                    <th colSpan={2}>
                                        Sesiones confirmadas
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr>
                                    <td className="text-center">{resultado.implementacion.confirmacion.numero_sesiones_mes}</td>
                                    <td className="text-center" colSpan={2}>{resultado.implementacion.confirmacion.sesiones_trabajadas}</td>
                                    {/*<td><span className={"semaforo_rn " + resultado.implementacion.confirmacion.semaforo} style={{ margin: 'auto' }}></span></td>*/}
                                </tr>
                                <tr style={{ backgroundColor: '#657FC5', color: '#fff' }}>
                                    <th colSpan={3}>
                                        Sesiones confirmadas con 7 dias de anticipación
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center">{resultado.implementacion.confirmacion.en_fecha}</td>
                                    <td className="text-center">{resultado.implementacion.confirmacion.porcentaje_en_fecha}</td>
                                    <td><span className={"semaforo_rn verde"} style={{ margin: 'auto' }}></span></td>
                                </tr>
                                <tr style={{ backgroundColor: '#657FC5', color: '#fff' }}>
                                    <th colSpan={3}>
                                        Sesiones confirmadas con menos de 7 dias de anticipación
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center">{resultado.implementacion.confirmacion.despues_fecha}</td>
                                    <td className="text-center">{resultado.implementacion.confirmacion.porcentaje_despues_de_fecha}</td>
                                    <td><span className={"semaforo_rn rojo"} style={{ margin: 'auto' }}></span></td>
                                </tr>
                                <tr style={{ backgroundColor: '#657FC5', color: '#fff' }}>
                                    <th colSpan={3}>
                                        Sesiones sin confirmar
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center">{resultado.implementacion.confirmacion.sin_confirmacion}</td>
                                    <td className="text-center">{resultado.implementacion.confirmacion.porcentaje_sin_confirmacion}</td>
                                    <td><span className={"semaforo_rn gris"} style={{ margin: 'auto' }}></span></td>
                                </tr>
                            </tbody>
                        </Table>

                        <Table className="mt-3">
                            <thead>
                                <tr style={{ backgroundColor: '#262F48', color: '#fff' }}>
                                    <th colSpan={3}>
                                        Sensibilizar/Inducir docentes
                                    </th>
                                </tr>
                                <tr style={{ backgroundColor: '#657FC5', color: '#fff' }}>
                                    <th>
                                        N° de docentes
                                    </th>
                                    <th colSpan={2}>
                                        Docentes Capacitados
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">{resultado.implementacion.capacitacion.numero_sesiones_mes}</td>
                                    <td className="text-center" colSpan={2}>{resultado.implementacion.capacitacion.sesiones_trabajadas}</td>
                                    {/*<td><span className={"semaforo_rn " + resultado.implementacion.capacitacion.semaforo} style={{ margin: 'auto' }}></span></td>*/}
                                </tr>
                                <tr style={{ backgroundColor: '#657FC5', color: '#fff' }}>
                                    <th colSpan={3}>
                                        Docentes Capacitados con 5 dias de anticipación
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center">{resultado.implementacion.capacitacion.en_fecha}</td>
                                    <td className="text-center">{resultado.implementacion.capacitacion.porcentaje_en_fecha}</td>
                                    <td><span className={"semaforo_rn verde"} style={{ margin: 'auto' }}></span></td>
                                </tr>
                                <tr style={{ backgroundColor: '#657FC5', color: '#fff' }}>
                                    <th colSpan={3}>
                                        Docentes Capacitados con menos de 5 dias de anticipación
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center">{resultado.implementacion.capacitacion.despues_fecha}</td>
                                    <td className="text-center">{resultado.implementacion.capacitacion.porcentaje_despues_de_fecha}</td>
                                    <td><span className={"semaforo_rn rojo"} style={{ margin: 'auto' }}></span></td>
                                </tr>
                                <tr style={{ backgroundColor: '#657FC5', color: '#fff' }}>
                                    <th colSpan={3}>
                                        Docentes sin capacitar
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center">{resultado.implementacion.capacitacion.sin_evidencia}</td>
                                    <td className="text-center">{resultado.implementacion.capacitacion.porcentaje_sin_evidencia}</td>
                                    <td><span className={"semaforo_rn gris"} style={{ margin: 'auto' }}></span></td>
                                </tr>

                            </tbody>
                        </Table>

                    </Card.Body>
                </Card>
                <Card className="p-3 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <Card.Title>Control</Card.Title>
                        <Button variant="primary" onClick={() => handleInfoModal('Control')} >Ver mas</Button>
                    </div>                    <Card.Body>
                        <p className='mb-3'>Este KPI evalúa la calidad docente mediante encuestas de satisfacción estudiantil, buscando un 90% o más por sesión. Los alumnos califican a los docentes según su adaptación a la metodología andragógica de INEDI Posgrados.</p>
                        <Table>
                            <thead>
                                <tr style={{ backgroundColor: '#262F48', color: '#fff' }}>
                                    <th colSpan={3}>
                                        Controlar calidad
                                    </th>
                                </tr>

                                <tr style={{ backgroundColor: '#657FC5', color: '#fff' }}>
                                    <th >
                                        Producto
                                    </th>
                                    <th colSpan={2} >
                                        Promedio del mes
                                    </th>
                                </tr>

                            </thead>
                            <tbody>

                                {resultado.control.productos ? resultado.control.productos.map((proyecto, i) => (
                                    <tr key={i}>
                                        <td className="text-center">{proyecto.nombre_proyecto}</td>
                                        <td className="text-center">{proyecto.promedio_bueno ? proyecto.promedio_bueno : 0.0} %</td>
                                        <td><span className={parseFloat(proyecto.promedio_bueno) >= 90 ? 'semaforo_rn verde' : 'semaforo_rn rojo'} style={{ margin: 'auto' }}></span></td>
                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan={3} className="text-center">Aún no hay encuestas respondidas</td>
                                    </tr>
                                }

                                <tr>
                                    <td className="text-center"><strong>Promedio global:</strong></td>
                                    <td className="text-center">{resultado.control.promedio ? parseFloat(resultado.control.promedio).toFixed(2) : 0.0} %</td>
                                    <td><span className={parseFloat(resultado.control.promedio) >= 90 ? 'semaforo_rn verde' : 'semaforo_rn rojo'} style={{ margin: 'auto' }}></span></td>
                                </tr>

                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-6">
                <Card className="p-3 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <Card.Title>Organización</Card.Title>
                        <Button variant="primary" onClick={() => handleInfoModal('Organizacion')} >Ver mas</Button>
                    </div>
                    <Card.Body>
                        <p className='mb-3'>Mide que cada sesión cuente con el 100% de los recursos 3 días antes de la sesión. </p>
                        <Table >
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: '#262F48', color: '#fff' }} colSpan={3}>
                                        Registrar logística
                                    </th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ backgroundColor: '#657FC5', color: '#fff', fontWeight: 'bold' }}>
                                        Productos en Ejecución:
                                    </td>
                                    <td colSpan={2} className="text-center">{resultado.organizacion.logistica.auditorio.numero_productos_activos}</td>

                                </tr>
                                <tr>
                                    <td style={{ backgroundColor: '#F6F8FA', fontWeight: 'bold' }}>Contratos de Auditorio o Plataforma</td>
                                    <td className="text-center">{resultado.organizacion.logistica.auditorio.sesiones_trabajadas}</td>
                                    <td><span className={"semaforo_rn " + resultado.organizacion.logistica.auditorio.semaforo} style={{ margin: 'auto' }}></span></td>
                                </tr>
                                <tr>
                                    <td style={{ backgroundColor: '#F6F8FA', fontWeight: 'bold' }}>Contratos de Impresión o embalaje</td>
                                    <td className="text-center">{resultado.organizacion.logistica.impresion.sesiones_trabajadas}</td>
                                    <td><span className={"semaforo_rn " + resultado.organizacion.logistica.impresion.semaforo} style={{ margin: 'auto' }}></span></td>
                                </tr>
                                <tr>
                                    <td style={{ backgroundColor: '#F6F8FA', fontWeight: 'bold' }}>Contratos de Productor Audio Visual</td>
                                    <td className="text-center">{resultado.organizacion.logistica.productor.sesiones_trabajadas}</td>
                                    <td><span className={"semaforo_rn " + resultado.organizacion.logistica.productor.semaforo} style={{ margin: 'auto' }}></span></td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table className="mt-3">
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: '#262F48', color: '#fff' }} colSpan={5}>
                                        Validar recursos 
                                    </th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ backgroundColor: '#657FC5', color: '#fff', fontWeight: 'bold' }}>
                                        N° de sesiones
                                    </td>
                                    <td colSpan={4} className="text-center">{resultado.organizacion.recursos_academicos.examenes.numero_sesiones_mes}</td>

                                </tr>
                                <tr>
                                    <td style={{ backgroundColor: '#F6F8FA', fontWeight: 'bold' }}>Sesiones con PodCasts</td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.podcasts.despues_fecha} ({resultado.organizacion.recursos_academicos.podcasts.porcentaje_despues_de_fecha})</td>
                                    <td><span className={"semaforo_rn rojo"} style={{ margin: 'auto' }}></span></td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.podcasts.en_fecha} ({resultado.organizacion.recursos_academicos.podcasts.porcentaje_en_fecha})</td>
                                    <td><span className={"semaforo_rn verde"} style={{ margin: 'auto' }}></span></td>
                                </tr>
                                <tr>
                                    <td style={{ backgroundColor: '#F6F8FA', fontWeight: 'bold' }}>Sesiones con Clase Online</td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.online.despues_fecha} ({resultado.organizacion.recursos_academicos.online.porcentaje_despues_de_fecha})</td>
                                    <td><span className={"semaforo_rn rojo"} style={{ margin: 'auto' }}></span></td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.online.en_fecha} ({resultado.organizacion.recursos_academicos.online.porcentaje_en_fecha})</td>
                                    <td><span className={"semaforo_rn verde"} style={{ margin: 'auto' }}></span></td>
                                </tr>
                                <tr>
                                    <td style={{ backgroundColor: '#F6F8FA', fontWeight: 'bold' }}>Sesiones con Clases OffLine</td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.offline.despues_fecha} ({resultado.organizacion.recursos_academicos.offline.porcentaje_despues_de_fecha})</td>
                                    <td><span className={"semaforo_rn rojo"} style={{ margin: 'auto' }}></span></td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.offline.en_fecha} ({resultado.organizacion.recursos_academicos.offline.porcentaje_en_fecha})</td>
                                    <td><span className={"semaforo_rn verde"} style={{ margin: 'auto' }}></span></td>
                                </tr>
                                <tr>
                                    <td style={{ backgroundColor: '#F6F8FA', fontWeight: 'bold' }}>Sesiones con Materiales</td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.materiales.despues_fecha} ({resultado.organizacion.recursos_academicos.materiales.porcentaje_despues_de_fecha})</td>
                                    <td><span className={"semaforo_rn rojo"} style={{ margin: 'auto' }}></span></td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.materiales.en_fecha} ({resultado.organizacion.recursos_academicos.materiales.porcentaje_en_fecha})</td>
                                    <td><span className={"semaforo_rn verde"} style={{ margin: 'auto' }}></span></td>
                    
                                </tr>
                                <tr>
                                    <td style={{ backgroundColor: '#F6F8FA', fontWeight: 'bold' }}>Sesiones con Actividades</td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.actividades.despues_fecha} ({resultado.organizacion.recursos_academicos.actividades.porcentaje_despues_de_fecha})</td>
                                    <td><span className="semaforo_rn rojo" style={{ margin: 'auto' }}></span></td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.actividades.en_fecha} ({resultado.organizacion.recursos_academicos.actividades.porcentaje_en_fecha})</td>
                                    <td><span className="semaforo_rn verde" style={{ margin: 'auto' }}></span></td>
                                </tr>
                                <tr>
                                    <td style={{ backgroundColor: '#F6F8FA', fontWeight: 'bold' }}>Sesiones con Quizzes</td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.examenes.despues_fecha} ({resultado.organizacion.recursos_academicos.examenes.porcentaje_despues_de_fecha})</td>
                                    <td><span className={"semaforo_rn rojo"} style={{ margin: 'auto' }}></span></td>
                                    <td className="text-center">{resultado.organizacion.recursos_academicos.examenes.en_fecha} ({resultado.organizacion.recursos_academicos.examenes.porcentaje_en_fecha})</td>
                                    <td><span className={"semaforo_rn verde"} style={{ margin: 'auto' }}></span></td>
                                </tr>

                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-6">

            </div>
        </div>
    );
};

export default ContenidoReporte;
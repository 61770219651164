import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//import '../../../../public/images/implementacion_formula.png';

const   InfoModal = ({ tipo }) => {

    const [contenido, setContenido] = useState('');
    useEffect(() => {

    }, []);

    if (tipo === 'Implementacion') {
        return (
            <div className="container p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <p>Este KPI <strong>mide la eficiencia en la contratación y sensibilización de docentes.</strong> El área de curaduría debe garantizar que, para cada sesión programada, <strong>los docentes sean confirmados y contratados al menos 7 días antes de su dictado. </strong>  Además, es fundamental que <strong>los docentes sean sensibilizados en la metodología ANDRO® con un mínimo de 5 días de anticipación, </strong> al menos 5 días antes, asegurando su preparación y alineación con los estándares institucionales.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/implementacion_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <ul>
                                    <li><strong>Contratación:</strong> 100% de las clases con docentes confirmados al menos 7 días antes de su dictado.</li>
                                    <li><strong>Sensibilización:</strong> 100% de los docentes sensibilizados en la metodología ANDRO® al menos 5 días antes de sus clases.</li>
                                </ul>
                            </div>
                            <div className='mb-3'>
                                <h5>Plan de Acción:</h5>
                                <ol className='mb-3'>
                                    <li><strong>Revisión del Calendario:</strong></li>
                                        <ul>
                                            <li>Identificar todas las sesiones activas al inicio de cada mes y priorizar las contrataciones y sensibilizaciones en función de sus fechas de dictado.</li>
                                        </ul>
                                    <li><strong>Gestión Activa de Contrataciones:</strong></li>
                                        <ul>
                                            <li>Contactar y confirmar la disponibilidad de los docentes con anticipación. Remitir las invitaciones con al menos 15 días de anticipación para garantizar la cobertura de todas las sesiones.</li>
                                        </ul>
                                    <li><strong>Programación de Sensibilizaciones:</strong></li>
                                        <ul>
                                            <li>Coordinar sesiones de inducción en la metodología ANDRO® para los docentes, alineándolos con los objetivos andragógicos y los estándares de calidad.</li>
                                        </ul>
                                    <li><strong>Monitoreo Diario:</strong></li>
                                        <ul>
                                            <li>A través de la Dayli (ceremonia de Scrum que consiste en que cada miembro indique que hizo ayer, que hará hoy y qué impedimentos tiene) para detectar posibles retrasos en la contratación o sensibilización de docentes.</li>
                                        </ul>
                                    <li><strong>Reporte Mensual:</strong></li>
                                        <ul>
                                            <li>Analizar los resultados mediante informes que comparen el avance real con las metas establecidas, identificando oportunidades de mejora en el proceso.</li>
                                        </ul>
                                </ol>
                            </div>

                            <p>Este KPI permite al curador estructurar sus tareas, optimizando la gestión de tiempos y recursos. Al garantizar contrataciones y sensibilizaciones anticipadas, se minimizan imprevistos que puedan comprometer la calidad de las sesiones. De este modo, se fomenta una experiencia académica integral, asegurando el éxito de los programas educativos y fortaleciendo la relación con los docentes.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    if (tipo === 'Control') {   
        return (
            <div className="container p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <p>Este KPI mide la <strong>calidad de la enseñanza </strong>a través de la satisfacción de los alumnos, con la meta de alcanzar un <strong>90% o más de satisfacción</strong> por sesión. Los alumnos completan una encuesta al final de cada clase donde califican a los docentes según su adaptación a la <strong>metodología andragógica</strong> de INEDI Posgrados.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>Garantizar que los docentes impartan clases alineadas con la metodología de INEDI, ofreciendo experiencias significativas para los estudiantes adultos. El área de curaduría tiene un rol esencial sensibilizando y capacitando a los docentes para lograr esta adaptación.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <p>La fórmula se calcularía sobre el porcentaje de satisfacción basado en las respuestas de las encuestas, con un <strong>margen de error del 5% y un nivel de confianza del 95%, considerando que el 50% de los alumnos participantes respondan.</strong></p>
                                {/*<img className='img-fluid' src="/images/control_formula.png" alt="Formula for KPI">*/}
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>Lograr <strong>90% o más de satisfacción</strong> por sesion, con una participación mínima del 50% de los alumnos.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de Acción:</h5>
                                <ul className='mt-2'>
                                    <li><strong>Encuestas de Satisfacción:</strong> Asegurar que al final de cada clase se realicen encuestas para medir la satisfacción y recoger retroalimentación directa.</li>
                                    <li><strong>Capacitación Docente:</strong> Sensibilizar y capacitar a los profesores en la metodología andragógica para mejorar su alineación con las necesidades de los alumnos adultos.</li>
                                    <li><strong>Seguimiento y Mejora Continua:</strong> Proveer apoyo y coaching a los docentes cuya calificación sea inferior al 90%, promoviendo su mejora.</li>
                                </ul>
                            </div>
                            <p>Este KPI contribuye a garantizar la calidad educativa alineada con los principios de INEDI, mejorando la experiencia de los alumnos y motivando a los docentes a mejorar constantemente.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    if (tipo === 'Organizacion') {
        return (
            <div className="container p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <p>Este KPI mide la <strong>disponibilidad del 100% de los recursos </strong> para cada sesión, asegurando que estén subidos y listos 3 días antes del inicio de la clase. Los recursos incluyen:</p>
                                <ul className='mt-2'>
                                    <li><strong>Sesión online</strong> (enlace de Zoom)</li>
                                    <li><strong>Sesión offline</strong> (enlace de la grabación de la clase)</li>
                                    <li><strong>Podcast:</strong> Audio de 5 minutos con introducción al tema por el profesor</li>
                                    <li><strong>Materiales:</strong> PPT y lecturas principales, secundarias, leyes, normas, etc.</li>
                                    <li><strong>Actividad:</strong> Cuaderno de trabajo con ejercicios prácticos</li>
                                    <li><strong>Quiz:</strong> Evaluación con 5 preguntas para medir el aprendizaje</li>
                                </ul>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>Garantizar la <strong>calidad y preparación oportuna</strong> de los recursos para cada sesión, mejorando la experiencia de aprendizaje y facilitando el trabajo del docente.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/organizacion_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>100% de las sesiones con recursos completos.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de Acción:</h5>
                                <ul className='mt-2'>
                                    <li><strong>Control del Calendario:</strong> Verificar semanalmente las sesiones programadas para asegurar que los recursos se suban con anticipación.</li>
                                    <li><strong>Coordinación Docente:</strong> Alinear con los profesores la entrega de materiales y grabaciones.</li>
                                    <li><strong>Reporte Mensual:</strong> Generar informes de cumplimiento para detectar y corregir áreas críticas.</li>
                                </ul>
                            </div>
                            <p>Este KPI garantiza que cada sesión cuente con los recursos necesarios, asegurando la <strong>calidad y continuidad</strong> del proceso educativo.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    if (tipo === 'Recaudacion') {
        return (
            <div className="container-fluid p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <p>Este KPI mide el porcentaje del total de mensualidades de los alumnos que se logra cobrar cada mes, evaluando el desempeño del ejecutivo de experiencia en la gestión de cobros. El objetivo es alcanzar al menos <strong>90% de recaudación mensual </strong>, considerando que el 10% restante puede corresponder a situaciones fuera del control del ejecutivo, como problemas económicos de los alumnos o situaciones excepcionales que dificulten el pago.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>El propósito de este KPI es asegurar la <strong>sostenibilidad financiera</strong> de la empresa mediante una gestión eficiente de los cobros, fortaleciendo la relación con los alumnos a través de una comunicación clara y oportuna. Cumplir este KPI significa que el ejecutivo de experiencia contribuye directamente al buen funcionamiento del negocio, garantizando ingresos estables y mejorando la satisfacción del alumno al evitar situaciones de morosidad prolongada.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/recaudacion_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Frecuencia de medición:</h5>
                                <p>Mensual</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>90% o más de recaudación cada mes.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de acción sugerido:</h5>
                                <ul className='mt-2'>
                                    <div>
                                        <li><h8><strong>Afiliación al Pago Recurrente</strong></h8></li>

                                        <p>
                                            Incentivar la afiliación de los alumnos al sistema de <strong>pago recurrente</strong>, donde las mensualidades se debitarán automáticamente cada mes de sus cuentas bancarias o tarjetas sin necesidad de gestión manual. Esto reducirá el trabajo operativo y aumentará la probabilidad de alcanzar la meta del 90%.
                                        </p>

                                        <ul className='mt-2 mb-2'>
                                            <li><strong>Acción recomendada</strong>: Comunicar los beneficios del pago recurrente, como la comodidad y evitar retrasos.</li>
                                            <li><strong>Meta interna</strong>: Aumentar cada mes el porcentaje de alumnos afiliados al pago automático para minimizar las gestiones de cobro.</li>
                                        </ul>
                                    </div>
                                    <div>

                                        <li><h8><strong>Generación de Confianza</strong></h8></li>

                                        <p>
                                            Establecer una<strong>relación cercana y transparente</strong>con los alumnos para fortalecer la confianza en la gestión de pagos. Escuchar sus inquietudes y, cuando sea necesario, ofrecer alternativas o facilidades de pago que se alineen con sus necesidades.
                                        </p>

                                        <ul className='mt-2 mb-2'>
                                            <li><strong>Acción recomendada</strong>: Realizar llamadas o enviar mensajes personalizados, demostrando empatía ante posibles inconvenientes.</li>
                                            <li><strong>Resultado esperado</strong>: Mejor retención de los alumnos y menor tasa de morosidad, evitando conflictos relacionados con los pagos.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <li><h8><strong>Seguimiento Proactivo y Medición a través de Campañas y Embudos</strong></h8></li>

                                        <p>
                                            Desarrollar <strong>campañas de recordatorio de pagos</strong>(vía correo, WhatsApp u otros canales) antes y después de las fechas de vencimiento. Estas campañas deben ser <strong>monitoreadas mediante embudos de conversión</strong> para evaluar su efectividad en cada etapa, desde la apertura del mensaje hasta el pago realizado.
                                        </p>

                                        <ul className='mt-2 mb-2'>
                                            <li><strong>Acción recomendada</strong>:  Implementar un sistema de funnel o embudo de seguimiento para identificar cuántos alumnos respondieron a los recordatorios y realizaron su pago. Ajustar las campañas según los resultados.</li>
                                            <li><strong>Resultado esperado</strong>: Mayor eficiencia en la gestión de cobros al optimizar las campañas y focalizar los esfuerzos en los alumnos con más probabilidades de pago.</li>
                                        </ul>
                                    </div>
                                </ul>


                            </div>
                            <p>Alcanzar esta meta de recaudación es clave no solo para el crecimiento financiero de la empresa, sino también para consolidar una experiencia positiva para los alumnos. Un cobro eficiente refleja una gestión organizada y confiable, lo que mejora la reputación del área y del ejecutivo. Además, cumplir con esta meta brinda la <strong>satisfacción personal de lograr objetivos mensuales claros</strong>, alineados con el éxito de la organización.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    if (tipo === 'Retencion') {
        return (
            <div className="container p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <h5>Descripción:</h5>
                                <p>Este KPI mide el porcentaje de alumnos que se mantienen activos durante el período, evaluando su permanencia no solo por el pago de mensualidades, sino también por su progreso académico, asistencia y acumulación de puntos.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>Garantizar que los alumnos no abandonen sus estudios, fomentando el compromiso mediante seguimiento de su progreso académico y motivándolos a alcanzar metas significativas en el sistema de puntos.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/retencion_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>90% o más de retención mensual.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de Acción:</h5>
                                <ul className='mt-2'>
                                    <li><strong>Seguimiento del Progreso:</strong> Monitorear la asistencia y el puntaje acumulado de los alumnos para identificar riesgos de deserción temprana.</li>
                                    <li><strong>Uso del Sistema de Puntos:</strong> Asegurar que los alumnos alcancen al menos <strong>700 puntos</strong> y motivar a quienes lleguen a <strong>1,000 puntos</strong> con reconocimientos académicos, como publicaciones o acceso a bolsas de trabajo.</li>
                                    <li><strong>Recuperación de Alumnos:</strong> Los alumnos que abandonan pasan al <strong>reporte de SOS</strong>, donde se aplican estrategias para reintegrarlos al mismo curso o transferirlos a productos similares, siguiendo las condiciones establecidas en la normativa vigente.</li>
                                </ul>
                            </div>
                            <p>Este KPI asegura que los alumnos no solo se mantengan matriculados, sino también comprometidos con su crecimiento académico, fortaleciendo su experiencia y brindándoles oportunidades de desarrollo profesional.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    if (tipo === 'Calidad') {
        return (
            <div className="container p-4">
                <Form>
                    <Form.Group>
                        <div className="kpi-description">
                            <div className='mb-3'>
                                <p>Este KPI mide la <strong>calidad de la enseñanza</strong> a través del nivel de satisfacción de los alumnos, con la meta de alcanzar un <strong>90% o más de satisfacción</strong> por sesión. Al finalizar cada clase, los alumnos completan una encuesta donde califican a los profesores según su adaptación a la <strong>metodología andragógica</strong> de INEDI Posgrados, enfocada en el aprendizaje para adultos.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Propósito:</h5>
                                <p>Asegurar que los profesores brinden clases alineadas con la metodología institucional, ofreciendo experiencias de aprendizaje significativas para adultos. El área de curaduría juega un rol clave sensibilizando y capacitando a los docentes para que logren esta adaptación.</p>
                            </div>
                            <div className='mb-3'>
                                <h5>Fórmula:</h5>
                                <img className='img-fluid' src="/images/control_formula.png" alt="Formula for KPI" />
                            </div>
                            <div className='mb-3'>
                                <h5>Meta:</h5>
                                <p>90% o más de satisfacción por sesión.</p>
                            </div>

                            <div className='mb-3'>
                                <h5>Plan de Acción:</h5>
                                <ul className='mt-2'>
                                    <li><strong>Encuestas al Final de Cada Sesión:</strong> Monitorear el nivel de satisfacción docente y recopilar retroalimentación directa de los alumnos.</li>
                                    <li><strong>Capacitación Continua:</strong> El área de curaduría sensibiliza y capacita a los profesores en la metodología andragógica para asegurar su adaptación a las necesidades de los alumnos adultos.</li>
                                    <li><strong>Mejora Continua:</strong> Identificar y trabajar con los profesores cuyas calificaciones estén por debajo del 90%, proporcionando coaching personalizado para elevar su desempeño.</li>
                                </ul>
                            </div>
                            <p>Este KPI asegura una enseñanza alineada con los principios institucionales, mejorando la experiencia de los alumnos y motivando a los profesores a ofrecer clases de alta calidad.</p>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }


};

export default InfoModal;
import React from 'react';
import { Table, Card } from 'react-bootstrap';
const resultadofidelizacion = ({ fidelizacion }) => {

    if (fidelizacion === undefined || !fidelizacion || fidelizacion.lenght === 0) return (
        <>
            <Card className="p-3 mb-3">
                <Card.Body>
                    No hay datos para mostrar
                </Card.Body>
            </Card>
        </>
    );
    return (
        <>
            {
                fidelizacion.map((proyecto, i) => (

                    <Card key={i} className="p-3 mb-3">
                        <Card.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th colSpan="4">{proyecto.nombre_proyecto}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan="4">Realizado del {proyecto.fecha_inicio}  hasta {proyecto.fecha_fin}</th>
                                    </tr>
                                    <tr>
                                        <th>Discentes Matriculados (Meta)</th>
                                        <th>Discentes Activos/Culminados</th>
                                        <th>Afiliados</th>
                                        <th>Brecha</th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        <td className="text-center">{proyecto.cantidad} (100%)</td>
                                        <td className="text-center">{parseInt(proyecto.activos) + parseInt(proyecto.culminados)}({proyecto.producto_pr})</td>
                                        <td className="text-center">{proyecto.afiliados}</td>

                                        <td className="text-center">{proyecto.desertados}({proyecto.brecha_pr})</td>
                                        <td><span className={parseFloat(proyecto.producto_pr) > 90 ? 'semaforo_rn verde' : 'semaforo_rn rojo'} style={{ margin: 'auto' }}></span></td>

                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                ))

            }
        </>
    );
};

export default resultadofidelizacion;

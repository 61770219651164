import React from 'react';
import {Button} from "react-bootstrap";
const Opcion = ({id_opcion,opcion_text,respuesta_correcta,eliminarOpcion}) => {

  
  return ( 
    <div style={{display:"flex", textAlign:"left", padding:"10px"}}>
      <div style={{minWidth:"250px"}}>{opcion_text} {respuesta_correcta ? '(R)': ''}</div>
      <div ><Button variant='danger'  onClick={() => eliminarOpcion(id_opcion)}> <i className="fa fa-trash" aria-hidden="true"></i> </Button></div>
    </div>
  );
}
 
export default Opcion;